
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, ref, provide } from 'vue';
import Tabs from './views/Tabs.vue'

import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: { IonApp, IonRouterOutlet, Tabs },
  setup () {
    // router
    const router = useRouter();

    // authentification state
    const auth = ref(false);
    // do login and redirect to start page
    const login = () => {
      auth.value = true;
      router.push('/start');
    };
    // do logout and redirect to login page
    const logout = () => {
      auth.value = false;
      router.push('/');
    };
    provide('auth', auth)
    provide('login', login)
    provide('logout', logout)
  }
});
