
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { apps, appsOutline, cafe, cafeOutline, people, peopleOutline, map, mapOutline } from 'ionicons/icons';

import { inject } from 'vue'

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    const auth = inject('auth');
    return {
      apps, appsOutline,
      cafe, cafeOutline,
      people, peopleOutline, 
      map, mapOutline,
      auth,
    }
  }
}
