import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'login',
    path: '/',
    component: () => import('@/views/PageLogin.vue')
  },
  {
    name: 'start',
    path: '/start',
    component: () => import('@/views/TabStart.vue')
  },
  {
    name: 'items',
    path: '/items',
    component: () => import('@/views/TabItems.vue')
  },
  {
    name: 'contacts',
    path: '/contacts',
    component: () => import('@/views/TabContacts.vue')
  },
  {
    name: 'locations',
    path: '/locations',
    component: () => import('@/views/TabLocations.vue')
  },
  {
    name: 'profile',
    path: '/profile',
    component: () => import('@/views/PageProfile.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
