<template>
  <ion-page>
    <ion-tabs>

      <ion-tab-bar slot="bottom" v-if="auth">

        <ion-tab-button tab="start" href="/start">
          <ion-icon v-if="$route.name === 'start'" :icon="apps" />
          <ion-icon v-else :icon="appsOutline" />
          <ion-label>Start</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="items" href="/items">
          <ion-icon v-if="$route.name === 'items'" :icon="cafe" />
          <ion-icon v-else :icon="cafeOutline" />
          <ion-label>Angebot</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="contacts" href="/contacts">
          <ion-icon v-if="$route.name === 'contacts'" :icon="people" />
          <ion-icon v-else :icon="peopleOutline" />
          <ion-label>Kontakte</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="locations" href="/locations">
          <ion-icon v-if="$route.name === 'locations'" :icon="map" />
          <ion-icon v-else :icon="mapOutline" />
          <ion-label>Orte</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { apps, appsOutline, cafe, cafeOutline, people, peopleOutline, map, mapOutline } from 'ionicons/icons';

import { inject } from 'vue'

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    const auth = inject('auth');
    return {
      apps, appsOutline,
      cafe, cafeOutline,
      people, peopleOutline, 
      map, mapOutline,
      auth,
    }
  }
}
</script>
